import { useTranslation } from 'react-i18next'
import { create } from 'zustand'
import { archive } from '../_app/constants'
import { scrollToId } from '../_app/utils'
import { Context } from '../_types'
import PastCompetitions from './pastCompetitions.tsx'
import WithApp from '@utils/withApp.tsx'

interface Page {
  showCompetitions: boolean
  setShowCompetitions: (showCompetitions: boolean | undefined) => void
}

export const useShowPastCompetitions = create<Page>()(set => ({
  showCompetitions: false,
  setShowCompetitions: showCompetitions => set({ showCompetitions }),
}))

type Props = {
  dogIds: Array<number>
}

function Component({ dogIds }: Props) {
  const { showCompetitions, setShowCompetitions } = useShowPastCompetitions()
  const { t } = useTranslation()

  return (
    <>
      {showCompetitions ? (
        <div id={archive}>
          <PastCompetitions dogIds={dogIds} />
        </div>
      ) : (
        <div className="my-16 flex justify-center">
          <button
            className="btn-outline"
            onClick={() => {
              setShowCompetitions(!showCompetitions)

              scrollToId(archive)
            }}>
            {t('showPastEvents')}
          </button>
        </div>
      )}
    </>
  )
}

export default function PastCompetitionsButton({
  context,
  dogIds,
}: {
  context: Context
  dogIds: Array<number>
}) {
  return (
    <WithApp context={context}>
      <Component dogIds={dogIds} />
    </WithApp>
  )
}
