import { useMemo } from 'react'
import { serializeCompetition } from '../_utils'
import { usePublishedCompetitions } from '../_app/utils/firestore.tsx'
import LoadingHorizontal from '../_app/components/loadingHorizontal.tsx'
import CompetitionCard from './competitionCard.tsx'
import { groupCompetitions } from './utils.ts'

type Props = {
  dogIds: Array<number>
}

export default function PastCompetitions({ dogIds }: Props) {
  const { data } = usePublishedCompetitions()

  const competitions = useMemo(() => {
    if (!data) {
      return null
    }

    return groupCompetitions(data).pastCompetitions
  }, [data])

  if (!competitions) {
    return (
      <div className="flex items-center justify-center my-16">
        <LoadingHorizontal />
      </div>
    )
  }

  return (
    <div className="grid grid-cols-1 gap-6 lg:grid-cols-2 my-16">
      {competitions.map(item => (
        <div className="mb-4 bg-white shadow-xl" key={item.id}>
          <CompetitionCard serializedCompetition={serializeCompetition(item)} dogIds={dogIds} />
        </div>
      ))}
    </div>
  )
}
